<template>
  <v-parallax src='../assets/background.png' class="principalClass">
    <v-container>

      <!-- <v-toolbar allign="center" justify="center">
        
        <v-img :width="100" aspect-ratio="1/1" src="D:\BackupJun24\PSI-Logo.png"></v-img>
        <v-toolbar-title>Title</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text>Home</v-btn>
          <v-btn text>About</v-btn>
        </v-toolbar-items>
      </v-toolbar> -->

      <v-row>
        <v-col>
          <v-img class="mx-auto" :width="250" aspect-ratio="1/1" src="../assets/PSI-Logo.png"></v-img>
        </v-col>
      </v-row>


      <v-container fluid class="chatSectionEmpty d-flex align-center justify-center">
        <!-- <v-container class="d-flex align-center justify-center">

          <v-row allign="center" justify="center">

            <v-col allign="center" justify="center" class="d-flex justify-center relative-container">
              <v-img class="background-image" :max-height="650" :max-width="650" aspect-ratio="4/3"
                src='../assets/box.png'></v-img>
              <v-img class="overlay-image" :max-height="350" :max-width="350" aspect-ratio="4/3"
                src='../assets/letter.png'></v-img>
            </v-col>

            <v-col class="d-flex justify-center">
                <v-img :max-height="650" :max-width="650" aspect-ratio="4/3" src='../assets/box.png'>
                </v-img>
              </v-col>

              <v-col class="d-flex justify-center">
                <v-img :max-height="650" :max-width="650" aspect-ratio="4/3" src='../assets/letter.png'>
                </v-img> 
              </v-col>

          </v-row>
          <v-img max-height="400" aspect-ratio="4/3" src='../assets/box.png'></v-img> -->
        <!-- <v-card class="squareCard" style="width: 55vh;height: 40vh; border-radius: 4%;">
            

             <v-row allign="center" justify="center">
              <v-col class="d-flex justify-center">
                <p class="text-center" style="position: absolute;">
                  Email Verified!
                </p>
              </v-col>

            </v-row>


          </v-card>

        </v-container> -->

        <v-container class="d-flex align-center justify-center" style="height: 100vh;">
    <v-row allign="center" justify="center">
      <v-col class="d-flex justify-center">
        <div class="relative-container">
          <v-img class="background-image" :max-height="650" :max-width="650" aspect-ratio="4/3" src='../assets/box.png'></v-img>
          <v-img class="overlay-image letter" style="margin-bottom: 10%;" :max-height="350" :max-width="350" aspect-ratio="4/3" src='../assets/letter.png'></v-img>
          <v-img class="overlay-image flowersWhite floatingWhite" :max-height="750" :max-width="750" aspect-ratio="4/3" src='../assets/flowers.png'></v-img>
          <v-img class="overlay-image flowers floating" :max-height="750" :max-width="750" aspect-ratio="4/3" src='../assets/whiteFlowers.png'></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
       
      </v-container>


      <v-container fluid class="sectionInfo bk">

        <v-row class="mt-12 mx-auto" allign="center" justify="center">
          <v-col class="d-flex justify-center">
            <!-- <p class="text-center" style="position: absolute;">
              Download the app
            </p> -->
            <v-img :max-height="380" :max-width="380" aspect-ratio="4/3" src='../assets/download.png'></v-img>
          </v-col>


        </v-row>
        <v-btn to="/About">Next Page</v-btn>
        <v-row class="mt-12 mx-auto" allign="center" justify="center">

          <v-col class="d-flex justify-center" cols="2">
            <v-img :max-height="200" :max-width="200" aspect-ratio="4/3" src='../assets/appstore.png'>
            </v-img>


          </v-col>

          <v-col class="d-flex justify-center" cols="2">
            <v-img :max-height="200" :max-width="200" aspect-ratio="4/3" src='../assets/google.png'>
            </v-img>


          </v-col>
        </v-row>

        <v-row class="mt-12 mx-auto" allign="center" justify="center">
          <v-col class="d-flex justify-center">

            <v-img :max-height="200" :max-width="700" aspect-ratio="4/3" src='../assets/yellowLine.png'>
            </v-img>
            <!-- <p class="text-center" style="position: absolute;">
              Follow us in our social networks!
            </p> -->
          </v-col>


        </v-row>


        <v-row class="mt-12 mx-auto" allign="center" justify="center">
          <v-col class="d-flex justify-center">

            <v-img :max-height="380" :max-width="380" aspect-ratio="4/3" src='../assets/follow.png'>
            </v-img>
            <!-- <p class="text-center" style="position: absolute;">
              Follow us in our social networks!
            </p> -->
          </v-col>


        </v-row>

        <v-row class="mt-12 mx-auto" allign="center" justify="center">

          <v-col class="d-flex justify-center" cols="1">
            <v-img :max-height="80" :max-width="80" aspect-ratio="4/3" src='../assets/fb.png'>
            </v-img>


          </v-col>

          <v-col class="d-flex justify-center" cols="1">
            <v-img :max-height="80" :max-width="80" aspect-ratio="4/3" src='../assets/instagram.png'>
            </v-img>


          </v-col>


          <v-col class="d-flex justify-center" cols="1">
            <v-img :max-height="80" :max-width="80" aspect-ratio="4/3" src='../assets/xlogo.png'>
            </v-img>


          </v-col>
        </v-row>




      </v-container>

      <!-- <v-card class="chatSectionEmpty">
    
  </v-card> -->
      <v-spacer></v-spacer>

      <!-- <div class="d-flex flex-column fill-height justify-center align-center text-white footer">
      <h1 class="text-h4 font-weight-thin mb-4">
        Footer
      </h1>
      <h4 class="subheading">
        Footer here Yair!
      </h4>
    </div> -->

    </v-container>
    <v-row class="image-row">
      <v-col class="d-flex justify-center">
        <v-img src='../assets/footer.png'>
        </v-img>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>

import axios from 'axios';
//import Form from './Form.vue';
export default {
  name: "App",
  data() {
    return {
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      sidebar: false,
      menuItems: [
        { title: 'Home', path: '/home', icon: 'mdi-home' },

      ]
    }
  },
  methods: {

    async getReportInfo() {
      try {
        console.log('dentro:');
        const response = await axios.get('http://localhost:5123/PetContoller/VerifyMail?dataurl=QtK2ZyERdcDFTD68P7FkpA==');
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
    redirectToPage() {
      this.$router.push('/Form');
    }

  },
  async mounted() {

    //http://localhost:5123/PetContoller/VerifyMail?dataurl=1
    //var data = new URL(location.href).searchParams.get('data');
    console.log('getResponse:');

    //this.getReportInfo();


    // let data = await axios.get('http://localhost:5123/PetContoller/VerifyMail?dataurl=3fdfdf').then(res => res.data);
    // console.log(data);
    // axios.get('http://localhost:5123/PetContoller/VerifyMail?dataurl=3fdfdf')
    //   .then(response => {
    //     console.log('En el bueno:');
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log('En el error:');
    //     console.error('Error:', error);
    //   });

  }
};
</script>
<style>
@import url(https://db.onlinewebfonts.com/c/3a163862f6cb41a600e12d99cf4a070d?family=Delightious);

.footer {
  color: black !important;
}

.image-row {
  margin-top: 10%;
  width: 100% !important;
  background-color: transparent;

}

.principalClass {
  max-height: 1900px;
}

.squareCard {
  /* width: 50vh;
  height: 50vh; */
  background-image: url('/src/assets/box.png');
  background-size: cover;
  background-position: center;

  /* background-image: url("https://www.ausa.com.mx/img/bg-whatsapp.png"); */
}

.bk {
  /* background-color: #bff4ff !important; */
  /* Cambia este color a tu preferencia */
}

.sectionInfo {
  font-family: 'Delightious', sans-serif;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 25px;
  /* background-image: url("https://www.ausa.com.mx/img/bg-whatsapp.png");
background-repeat: repeat; */
  min-height: 100% !important;
  height: 40vh !important;
  border-width: 0px;
  width: 100%;
  justify-content: center !important;
  color: white;

}

.chatSectionEmpty {
  font-family: 'Delightious', sans-serif;
  margin-bottom: -100px;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 40px;
  /* background-image: url("https://www.ausa.com.mx/img/bg-whatsapp.png");
background-repeat: repeat; */
  min-height: 100% !important;
  height: 60vh !important;
  border-width: 0px;
  width: 100%;
  min-height: 40vh;
  justify-content: center !important;
  /* background-color: #bff4ff !important; */
}

.relative-container {
  position: relative;
  width: 650px; /* Ajusta el tamaño según sea necesario */
  height: 487.5px; /* Ajusta el tamaño según sea necesario para mantener el aspecto 4/3 */
}

.background-image,
.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}


.letter{
  top: 45% !important;
}

.flowersWhite{
  max-width: none !important;
  max-height: none !important;
  width: 70vh !important;
  top: 0% !important;
  left: -12% !important;
  /* transform: translate(-50%, -50%) !important; */

}
.flowers{
  max-width: none !important;
  max-height: none !important;
  width: 70vh !important;
  top: 0% !important;
  left: -12% !important;
}

.overlay-image:nth-child(2) {
  z-index: 2;
}

.overlay-image:nth-child(3) {
  z-index: 3;
}

.overlay-image:nth-child(4) {
  z-index: 4;
}

.floating {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 20px);
  }

  100% {
    transform: translate(0, -0px);
  }
} 


.floatingWhite {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
} 
</style>
