<template>
  <v-parallax src='../assets/BackColor.png'>
    <v-container>

      <v-overlay :model-value="this.overlay" class="align-center justify-center">
        <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col>
          <v-img class="mx-auto" :width="220" aspect-ratio="1/1" src="../assets/PSI-Logo.png"></v-img>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>

      <v-container class="FormContainer">



        <v-row align="center" justify="center">

          <v-card class="textForm">
            <v-card-text>
              <v-card-title style="font-size: 2.5vh;">
                Pre registration
              </v-card-title>

              <v-form ref="form">
                <v-text-field v-model="name" color :rules="nameRules" outlined label="User" required></v-text-field>

                <!-- <v-select v-model="select" :items="items" :rules="[v => !!v || 'Item is required']" label="Item"
                required></v-select> -->

                <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

                <!-- <v-checkbox v-model="checkbox" :rules="[v => !!v || 'You must agree to continue!']"
                  label="Do you agree?" required></v-checkbox> -->

                <v-text-field v-model="password" color :counter="10" :rules="passwordRules" outlined label="Password"
                  required></v-text-field>

                <v-text-field v-model="confirmPassword" color :counter="10" :rules="confirmPasswordRules" outlined
                  label="Confirm Password" required></v-text-field>




                <!-- <div class="d-flex flex-column">
                <v-btn class="mt-4" color="success" block @click="validate">
                  Validate
                </v-btn>

                <v-btn class="mt-4" color="error" block @click="reset">
                  Reset Form
                </v-btn>

                <v-btn class="mt-4" color="warning" block @click="resetValidation">
                  Reset Validation
                </v-btn>
              </div> -->
              </v-form>

            </v-card-text>

            <v-card-actions>
              <!-- <v-btn color="deep-purple-accent-4" text="Learn More" variant="text"></v-btn> -->
              <v-row align="center" justify="center">
                <v-col style="max-width: 30%;">
                  <v-btn variant="tonal" class="mt-2" block @click="SendData">
                    Sign In
                  </v-btn>
                </v-col>

                <v-col style="max-width: 30%;">
                  <v-btn variant="tonal" class="mt-2" block @click="validate">
                    Clear
                  </v-btn>
                </v-col>

              </v-row>
            </v-card-actions>

          </v-card>




        </v-row>

      </v-container>




    </v-container>

  </v-parallax>


</template>

<script>

import axios from 'axios';

export default {
  name: "App",
  data() {
    return {
      overlay: false,
      sidebar: false,
      menuItems: [
        { title: 'Home', path: '/home', icon: 'mdi-home' },

      ],
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length > 7) || 'Name must be more than 7 characters',
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length > 7) || 'Password must be more than 7 characters',
      ],
      confirmPassword: '',
      confirmPasswordRules: [
        v => !!v || 'Password is required',
        v => (this.password === this.confirmPassword) || 'Password must match'],
      // passwordConfirmRules: [
      //   v => !!v || 'Confirm password is required',
      //   v => (v && v.length > 7 && v == passwordConfirm) || 'Password must be equals',
      // ],
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: false,

      email: '',
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is requred.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
    }
  },
  methods: {

    async validate() {
      const { valid } = await this.$refs.form.validate()

      if (valid) alert('Form is valid')
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    redirectToPage() {
      this.$router.push('/Form');
    },
    async SendData() {
      try {
        const { valid } = await this.$refs.form.validate()

        if (valid) {
          console.log('Enviando datos');

          this.overlay = true;
          let formData = new FormData();
          formData.append("user", "masdedra");


          var user = "user=" + this.name;
          var email = "&email=" + this.email;
          var password = "&password=" + this.password;

          const response = await axios.post('http://localhost:5123/PetContoller/PreRegister?' + user + email + password);


          console.log(response);

          this.overlay = false;

        }
        else {
          console.log("Datos incorrectos");
        }




      } catch (error) {
        console.error(error);
      }
    },

  },
  async mounted() {

  }
};
</script>
<style>
@import url(https://db.onlinewebfonts.com/c/3a163862f6cb41a600e12d99cf4a070d?family=Delightious);

.footer {
  color: black !important;
}

.SquareCar {
  width: 50vh;
  height: 50vh;
}

.textForm {
  width: 50%;
  height: 50%;
  border-radius: 3%;
}

.text-white input {
  color: black !important;

}

.sectionInfo {
  font-family: 'Delightious', sans-serif;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 25px;
  min-height: 100% !important;
  height: 40vh !important;
  border-width: 0px;
  width: 100%;
  justify-content: center !important;
  color: white;

}

.chatSectionEmpty {
  font-family: 'Delightious', sans-serif;
  margin-bottom: -100px;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 40px;
  min-height: 100% !important;
  height: 60vh !important;
  border-width: 0px;
  width: 100%;
  min-height: 40vh;
  justify-content: center !important;
}

.FormContainer {
  margin-top: 10%;
  /* justify-content: center !important; */
  max-width: 80%;
  font-family: 'Delightious', sans-serif;
}
</style>
